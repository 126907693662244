import React, { forwardRef } from 'react';
import classNames from 'classnames';
import BaseButton from '../BaseButton';
import { ButtonProps } from './types';
import classes from './index.module.scss';

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(({
    children,
    disabled = false,
    intent = 'primary',
    size = 'md',
    variant = 'filled',
    loading = false,
    className: customClassName,
    ...rest
}, ref) => {
    const className = classNames(
        classes[`intent-${intent}`],
        classes[`variant-${variant}`],
        { [classes.disabled]: disabled },
        customClassName
    );

    return (
        <BaseButton {...rest} size={size} loading={loading} disabled={disabled} ref={ref} className={className}>
            {children}
        </BaseButton>
    );
});

Button.displayName = 'Button';

export default Button;
