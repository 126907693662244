import React from 'react';
import classNames from 'classnames';
import classes from '../index.module.scss';
import { SkeletonSquareProps } from '../types';

const SkeletonSquare = ({
  width = '100%',
  height = '16px',
  maxHeight,
  maxWidth = '100%',
  dataTestId,
}: SkeletonSquareProps) => (
  <div
    className={classNames(classes.skeletonBackground, classes.skeletonSquare)}
    style={{
      width,
      height,
      maxHeight,
      maxWidth,
    }}
    data-testid={dataTestId}
  />
);
SkeletonSquare.displayName = 'SkeletonSquare';

export default SkeletonSquare;
