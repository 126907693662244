export const DURATION = {
    fast: '1s',
    moderate: '1.5s',
    slow: '2s',
} as const;

export const DELAY = {
    fast: '250ms',
    moderate: '500ms',
    slow: '750ms',
} as const;
