import React from 'react';
import { Wipe } from '@fiverr-private/transition';
import { SkeletonProps } from './types';

import classes from './index.module.scss';

const Skeleton = ({
  children,
  animationDuration = 'moderate',
  animationDelay = 'moderate',
  width = 'fit-content',
  dataTestId,
}: SkeletonProps) => (
  <div
    className={classes.skeletonBox}
    style={{
      width,
    }}
    data-testid={dataTestId}
  >
    <Wipe animationDuration={animationDuration} animationDelay={animationDelay} />
    {children}
  </div>
);

Skeleton.displayName = 'Skeleton';

export default Skeleton;
