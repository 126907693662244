import { logger } from '@fiverr-private/obs';
import pathfinder, { Pathfinder } from '@fiverr-private/pathfinder';
import transformUrl from './transform-url';
import { AllowedLocale } from './types';

/**
 * Represents a URL route manager for modifying and handling Fiverr URLs.
 */
class Route {
    url: string;

    /**
     * Creates a new Route instance with the specified URL.
     * @param url - The initial URL.
     * @throws Throws an error if the URL is empty.
     */
    constructor(url: string) {
        if (!url) {
            throw new Error('[Domingo] URL is required');
        }
        this.url = url;
    }

    /**
     * Replace path of the current URL using pathfinder.
     * Log an error (won't throw) in case pathfinder encounters an issue.
     * @param args - Arguments to be passed to pathfinder.
     * @returns {Route} Returns the Route instance for method chaining.
     *
     * @example <caption>Example usage from the exposed Domingo API.</caption>
     * const url = 'https://www.fiverr.com/ahmedsani355/convert-your-design-into-webpage';
     * Domingo(url).path('freelancer_page', { username: 'alonda' }).url;
     * // returns 'https://www.fiverr.com/freelancers/alonda'
     */
    path(...args: Parameters<Pathfinder>) {
        let path = '';
        try {
            path = pathfinder(...args);
            if (path) {
                this.removePath();
                this.url = this.url + path;
            }
        } catch (error) {
            logger.error(error as Error, {
                message: `[Domingo] Could not create path from pathfinder ${(error as Error).message}`,
                url: this.url,
            });
        }
        return this;
    }

    /**
     * Removes the path from the current URL.
     * Log an error (won't throw) in case the current URL is not valid.
     * @returns Returns the Route instance for method chaining.
     *
     * @example <caption>Example usage from the exposed Domingo API.</caption>
     * const url = 'https://www.fiverr.com/ahmedsani355/convert-your-design-into-webpage';
     * Domingo(url).removePath().url;
     * // returns 'https://www.fiverr.com'
     */
    removePath() {
        try {
            const url = new URL(this.url);
            this.url = url.origin;
        } catch (error) {
            logger.error(error as Error, {
                message: `[Domingo] Could not remove path, url is not valid ${(error as Error).message}`,
                url: this.url,
            });
        }
        return this;
    }

    /**
     * Migrates to the pro domain.
     * @returns Returns the Route instance for method chaining.
     *
     * @example <caption>Example usage from the exposed Domingo API.</caption>
     * const url = 'https://www.fiverr.com/ahmedsani355/convert-your-design-into-webpage';
     * Domingo(url).pro().url;
     * // returns 'https://pro.fiverr.com/ahmedsani355/convert-your-design-into-webpage'
     */
    pro() {
        this.url = transformUrl(this.url, 'pro');
        return this;
    }

    /**
     * Removes the pro subdomain from the domain.
     * @returns Returns the Route instance for method chaining.
     *
     * @example <caption>Example usage from the exposed Domingo API.</caption>
     * const url = 'https://pro.fiverr.com/ahmedsani355/convert-your-design-into-webpage';
     * Domingo(url).removePro().url;
     * // returns 'https://fiverr.com/ahmedsani355/convert-your-design-into-webpage'
     */
    removePro() {
        this.url = transformUrl(this.url, '!pro');
        return this;
    }

    /**
     * Migrates to a locale-specific domain.
     * @param newLocale - The new locale to migrate to.
     * @returns Returns the Route instance for method chaining.
     *
     * @example <caption>Example usage from the exposed Domingo API.</caption>
     * const url = 'https://www.fiverr.com/ahmedsani355/convert-your-design-into-webpage';
     * Domingo(url).locale('fr').url;
     * // returns 'https://fr.fiverr.com/ahmedsani355/convert-your-design-into-webpage'
     */
    locale(newLocale: AllowedLocale) {
        this.url = transformUrl(this.url, 'locale', newLocale);
        return this;
    }

    /**
     * Removes the locale subdomain from the domain.
     * @returns Returns the Route instance for method chaining.
     *
     * @example <caption>Example usage from the exposed Domingo API.</caption>
     * const url = 'https://fr.fiverr.com/ahmedsani355/convert-your-design-into-webpage';
     * Domingo(url).removeLocale().url;
     * // returns 'https://fiverr.com/ahmedsani355/convert-your-design-into-webpage'
     */
    removeLocale() {
        this.url = transformUrl(this.url, '!locale');
        return this;
    }

    /**
     * Migrates to the auth domain.
     * @returns Returns the Route instance for method chaining.
     *
     * @example <caption>Example usage from the exposed Domingo API.</caption>
     * const url = 'https://fiverr.com/ahmedsani355/convert-your-design-into-webpage';
     * Domingo(url).auth().url;
     * // returns 'https://auth.fiverr.com/ahmedsani355/convert-your-design-into-webpage'
     */
    auth() {
        this.url = transformUrl(this.url, 'auth');
        return this;
    }
}

export const Domingo = (url: string) => new Route(url);
