import React from 'react';
import { DELAY, DURATION } from './constants';
import { WipeProps } from './types';

import classes from './index.module.scss';

const Wipe = ({ animationDuration = 'moderate', animationDelay = 'moderate', dataTestId }: WipeProps) => (
  <div
    className={classes.wipe}
    style={{
      animationDelay: DELAY[animationDelay],
      animationDuration: DURATION[animationDuration],
    }}
    data-testid={dataTestId}
  />
);

Wipe.displayName = 'Wipe';

export default Wipe;
