import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { SpinnerAnimatedIcon } from '@fiverr-private/icons';
import { BaseButtonProps } from './types';
import classes from './index.module.scss';

const BaseButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, BaseButtonProps>(({
    children,
    href,
    size = 'md',
    loading = false,
    disabled=false,
    className: customClassName,
    ...rest
}, ref) => {
    const Element: any = href ? 'a' : 'button';
    const role = href ? 'link' : 'button';
    const className = classNames(
        classes.button,
        classes[`size-${size}`],
        { [classes.loading]: loading },
        { [classes.disabled]: disabled },
        customClassName
    );

    return (
        <Element {...rest} role={role} ref={ref} href={href} disabled={disabled} className={className}>
            {children}
            { loading &&
                <span className={classes.loadingIcon}>
                    <SpinnerAnimatedIcon color="currentColor"/>
                </span>
            }
        </Element>
    );
});

BaseButton.displayName = 'BaseButton';

export default BaseButton;
